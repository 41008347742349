import { Box, Typography, useTheme, MenuItem, FormControl, Select, IconButton, Modal, Button, TextField } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { useEffect, useState } from "react";
import Header from "../../components/Header";
import { createClient } from "@supabase/supabase-js";
import emailjs from '@emailjs/browser';
import DownloadIcon from '@mui/icons-material/Download';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import * as XLSX from 'xlsx';
import orderBy from 'lodash/orderBy';

// Import DatePicker components
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

emailjs.init("htlhXEhJZcUdUYUmm");

// Initialize Supabase Client
const supabase = createClient(
  'https://hovmhwftsrfwtqkgzayn.supabase.co',
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imhvdm1od2Z0c3Jmd3Rxa2d6YXluIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzY3NTgzOTcsImV4cCI6MjA1MjMzNDM5N30.Ocmt2Fu8E6aIzYsc9eyVSgi_eP2IWSIASMTTJNcLjfk'
);

const ContestEntries = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [guestData, setGuessData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageData, setSelectedImageData] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [editingRemarks, setEditingRemarks] = useState(null);
  const [remarksText, setRemarksText] = useState("");
  const [sortModel, setSortModel] = useState([]);
  // Date range state variables (using Date objects)
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const userRole = localStorage.getItem("userRole");

  // Helper function to format dates
  const formatDateForExport = (dateValue) => {
    if (!dateValue) return "";
    const date = new Date(dateValue);
    return date.toLocaleString("en-GB", {
      timeZone: "Asia/Singapore",
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }).replace(",", "");
  };

  const updateRemarks = async (id, remarks) => {
    try {
      const { error } = await supabase
        .from('cust_dt')
        .update({ remarks: remarks })
        .eq('id', id);
      
      if (error) throw error;

      // Update local state
      setGuessData(prevData =>
        prevData.map(row =>
          row.id === id ? { ...row, remarks: remarks } : row
        )
      );
      setEditingRemarks(null);
      setRemarksText("");
    } catch (error) {
      console.error('Error updating remarks:', error.message);
    }
  };

  const applyFilter = () => {
    // Filter guestData based on the tng_code and selected date range
    const data = guestData.filter((row) => {
      if (!row.tng_code || row.tng_code.trim() === "") return false;
      const entryDate = new Date(row.created_at);
      if (startDate && entryDate < startDate) return false;
      if (endDate) {
        const adjustedEndDate = new Date(endDate);
        adjustedEndDate.setHours(23, 59, 59, 999);
        if (entryDate > adjustedEndDate) return false;
      }
      return true;
    });
    setFilteredData(data);
  };

  const handleExportData = () => {
    const dataToExport = (filteredData.length > 0 ? filteredData : guestData.filter(
      (row) => row.tng_code && row.tng_code.trim() !== ""
    ));
    
    const sortedData = sortModel.length > 0
      ? orderBy(
          dataToExport,
          sortModel.map(item => item.field),
          sortModel.map(item => item.sort)
        )
      : dataToExport;
      
    // Transform created_at field to match the display format.
    const transformedData = sortedData.map(row => ({
      ...row,
      created_at: formatDateForExport(row.created_at),
    }));
    
    const worksheet = XLSX.utils.json_to_sheet(transformedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Customer Entries");
    XLSX.writeFile(workbook, "customer_entries.xlsx");
  };

  const handleExportSelected = () => {
    const dataToExport = (filteredData.length > 0 ? filteredData : guestData.filter(
      (row) => row.tng_code && row.tng_code.trim() !== ""
    ));
    const selectedData = dataToExport.filter(row => selectedRows.includes(row.id));
    const sortedSelectedData = sortModel.length > 0
      ? orderBy(
          selectedData,
          sortModel.map(item => item.field),
          sortModel.map(item => item.sort)
        )
      : selectedData;
    
    const transformedData = sortedSelectedData.map(row => ({
      ...row,
      created_at: formatDateForExport(row.created_at),
    }));
    
    const worksheet = XLSX.utils.json_to_sheet(transformedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Selected Entries");
    XLSX.writeFile(workbook, "selected_entries.xlsx");
  };

  const updateBulkStatus = async (newStatus) => {
    for (const id of selectedRows) {
      await updateEntryStatus(id, newStatus);
    }
  };

  useEffect(() => {
    fetchGuestData();
  }, []);

  const fetchGuestData = async () => {
    try {
      const { data, error } = await supabase
        .from('cust_dt')
        .select('*');
      if (error) throw error;
      setGuessData(data);
    } catch (error) {
      console.log('Error fetching guest data:', error.message);
    }
  };

  const updateEntryStatus = async (id, newStatus) => {
    const statusValue = newStatus === "Approve" ? true : false;
    try {
      const { error } = await supabase
        .from('cust_dt')
        .update({ entries_status: statusValue })
        .eq('id', id);
      
      if (error) throw error;

      setGuessData(prevData =>
        prevData.map(row =>
          row.id === id ? { ...row, entries_status: statusValue } : row
        )
      );
    } catch (error) {
      console.error('Error updating status:', error.message);
    }
  };

  const columns = [
    {
      field: "created_at",
      headerName: "Date of Entry",
      flex: 1,
      renderCell: (params) => {
        if (!params.row.created_at) return "No date";
        const date = new Date(params.row.created_at);
        if (isNaN(date.getTime())) return "Invalid Date";
        return date.toLocaleString("en-GB", {
          timeZone: "Asia/Singapore",
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        }).replace(",", "");
      },
    },
    {
      field: "contact",
      headerName: "Mobile Number",
      flex: 1,
      renderCell: (params) => "0" + params.row.contact,
    },
    {
      field: "age",
      headerName: "Age Group",
      flex: 1,
    },
    {
      field: "race",
      headerName: "Race",
      flex: 1,
    },
    {
      field: "state",
      headerName: "State",
      flex: 1,
    },
    {
      field: "current_brand",
      headerName: "Current Brand",
      flex: 1,
    },
    {
      field: "current_variant",
      headerName: "Current Variant",
      flex: 1,
    },
    {
      field: "q1_since1954",
      headerName: "Q1 - Since 1954",
      flex: 1,
      renderCell: (params) => (params.value === true ? "Yes" : "No"),
    },
    {
      field: "q2_freshswipe",
      headerName: "Q2 - FreshSwipe",
      flex: 1,
      renderCell: (params) => (params.value === true ? "Yes" : "No"),
    },
    {
      field: "unique_code",
      headerName: "Unique Code",
      flex: 1,
    },
    {
      field: "tng_code",
      headerName: "TnG PIN Received",
      flex: 1,
    },
    {
      field: "remarks",
      headerName: "Remarks",
      flex: 1.5,
      renderCell: (params) => {
        const isEditing = editingRemarks === params.row.id;
        if (isEditing) {
          return (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                width: '100%'
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <TextField
                fullWidth
                multiline
                variant="outlined"
                value={remarksText}
                onChange={(e) => setRemarksText(e.target.value)}
                onKeyDown={(e) => e.stopPropagation()}
                onClick={(e) => e.stopPropagation()}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    color: colors.grey[100],
                    '& fieldset': { borderColor: colors.grey[100] },
                    '&:hover fieldset': { borderColor: colors.greenAccent[500] },
                  }
                }}
              />
              <IconButton 
                onClick={(e) => {
                  e.stopPropagation();
                  updateRemarks(params.row.id, remarksText);
                }}
                sx={{ color: colors.greenAccent[500] }}
              >
                <CheckIcon />
              </IconButton>
            </Box>
          );
        }
  
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              width: '100%'
            }}
          >
            <Typography
              variant="body2"
              sx={{
                whiteSpace: 'normal',
                overflow: 'hidden',
                flex: 1
              }}
            >
              {params.value || "No remarks"}
            </Typography>
            <IconButton 
              onClick={(e) => {
                e.stopPropagation();
                setEditingRemarks(params.row.id);
                setRemarksText(params.value || "");
              }}
              sx={{ color: colors.blueAccent[400] }}
            >
              <EditIcon />
            </IconButton>
          </Box>
        );
      }
    }
  ].filter(column => {
    if (userRole === "viewer") {
      return !["remarks"].includes(column.field);
    }
    return true;
  });

  return (
    <Box m="20px">
      <Header title="WINSTON REJUVENATION" subtitle="Customer Survey Entries" />
      
      {userRole !== "viewer" && (
        // Container with two sections: left for date pickers/filter, right for download buttons.
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          {/* Left Section: Date Pickers and Apply Filter Button */}
          <Box display="flex" alignItems="center" gap={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <Button
              onClick={applyFilter}
              variant="contained"
              sx={{
                backgroundColor: colors.blueAccent[700],
                '&:hover': { backgroundColor: colors.blueAccent[800] }
              }}
            >
              Apply Date Filter
            </Button>
          </Box>
          {/* Right Section: Download Buttons */}
          <Box display="flex" alignItems="center" gap={2}>
            <Button
              onClick={handleExportData}
              variant="contained"
              sx={{
                backgroundColor: colors.blueAccent[700],
                '&:hover': { backgroundColor: colors.blueAccent[800] }
              }}
              startIcon={<DownloadIcon />}
            >
              Download All Data
            </Button>
            <Button
              onClick={handleExportSelected}
              variant="contained"
              disabled={selectedRows.length === 0}
              sx={{
                backgroundColor: colors.blueAccent[700],
                '&:hover': { backgroundColor: colors.blueAccent[800] }
              }}
              startIcon={<DownloadIcon />}
            >
              Download Selected ({selectedRows.length})
            </Button>
          </Box>
        </Box>
      )}
      
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": { border: "none" },
          "& .MuiDataGrid-cell": { borderBottom: "none" },
          "& .name-column--cell": { color: colors.greenAccent[300] },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none"
          },
          "& .MuiDataGrid-virtualScroller": { backgroundColor: colors.primary[400] },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700]
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`
          },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={
            filteredData.length
              ? filteredData
              : guestData.filter((row) => row.tng_code && row.tng_code.trim() !== "")
          }
          columns={columns}
          getRowHeight={() => 40}
          rowSelectionModel={selectedRows}
          onRowSelectionModelChange={(newSelection) => {
            setSelectedRows(newSelection);
          }}
          sortModel={sortModel}
          onSortModelChange={(newModel) => setSortModel(newModel)}
          initialState={{
            sorting: {
              sortModel: [{ field: 'id', sort: 'asc' }],
            },
          }}
          components={{ Toolbar: GridToolbar }}
          sx={{
            fontSize: '13px',
            '& .MuiDataGrid-cell': {
              borderBottom: "none",
              display: 'flex',
              alignItems: 'center',
              whiteSpace: 'normal !important',
              lineHeight: '20px !important',
              maxHeight: '120px',
              overflow: 'auto',
            },
            '& .Mui-selected': {
              backgroundColor: `${colors.greenAccent[700]} !important`,
            }
          }}
          showCellRightBorder={false}
          showColumnRightBorder={false}
          columnOrder={[...columns.map(col => col.field), '__check__']}
        />
      </Box>
    </Box>
  );
};

export default ContestEntries;
