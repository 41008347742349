import { ResponsivePie } from "@nivo/pie";
import { tokens } from "../theme";
import { useTheme } from "@mui/material";

const PieChart = ({ data }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <ResponsivePie
      data={data}
      colors={datum => {
        // Example: use different theme tokens based on race.
        switch (datum.id) {
          case "Malay":
            return colors.blueAccent[700];
          case "Chinese":
            return colors.redAccent[700];
          case "Indian":
            return colors.greenAccent[700];
          case "Others":
            return colors.grey[700];
          default:
            return colors.grey[500];
        }
      }}
      theme={{
        tooltip: {
          container: {
            background: "#fff", // white background
            color: "#000",       // black text
            fontSize: 12,
            borderRadius: "4px",
            boxShadow: "0 1px 2px rgba(0, 0, 0, 0.25)",
          },
        },
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },
          legend: {
            text: {
              fill: colors.grey[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        legends: {
          text: {
            fill: colors.grey[100],
          },
        },
        labels: {
          text: {
            fontSize: 14,
            fontFamily: theme.typography.fontFamily,
            fill: colors.grey[100],
          },
        },
      }}
      margin={{ top: 20, right: 50, bottom: 50, left: 50 }}
      innerRadius={0.7}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor={colors.grey[100]}
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      enableArcLabels={true}
      arcLabel={d =>
        `${d.data.value} (${d.data.percentage}%)`
      }
      arcLabelsRadiusOffset={0.4}
      arcLabelsSkipAngle={7}
    //   arcLabelsTextColor={{
    //     from: "color",
    //     modifiers: [["darker", 2]],
    //   }}
      tooltip={({ datum }) => (
        <div
          style={{
            background: "#fff",
            padding: "5px 10px",
            borderRadius: "4px",
            boxShadow: "0 1px 2px rgba(0, 0, 0, 0.25)",
            display: "flex",
            alignItems: "center",
          }}
        >
          {/* Color swatch */}
          <div
            style={{
              width: "12px",
              height: "12px",
              borderRadius: "50%",
              background: datum.color,
              marginRight: "5px",
            }}
          ></div>
          {/* Label text */}
          <div style={{ color: "#000" }}>
            {datum.id}: {datum.data.value} ({datum.data.percentage}%)
          </div>
        </div>
      )}
      
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
    //   legends={[
    //     {
    //       anchor: "bottom",
    //       direction: "row",
    //       justify: false,
    //       translateX: 0,
    //       translateY: 56,
    //       itemsSpacing: 0,
    //       itemWidth: 100,
    //       itemHeight: 18,
    //       itemTextColor: "#999",
    //       itemDirection: "left-to-right",
    //       itemOpacity: 1,
    //       symbolSize: 18,
    //       symbolShape: "circle",
    //       effects: [
    //         {
    //           on: "hover",
    //           style: {
    //             itemTextColor: "#000",
    //           },
    //         },
    //       ],
    //     },
    //   ]}
    />
  );
};

export default PieChart;
