import React, { useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";
  const userRole = localStorage.getItem("userRole");
  // Normalize username for a robust check.
  const username = localStorage.getItem("rememberedUsername")?.trim().toLowerCase();
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  let redirectPath = null;

  if (isAuthenticated && userRole === "admin") {
    // Block cdadminjti from accessing "/entries"
    if (username === "cdadminjti" && location.pathname === "/entries") {
      redirectPath = "/survey";
    }
    // Block cdadmin from accessing "/dashboard" and "/survey"
    else if (
      username === "cdadmin" &&
      (location.pathname.startsWith("/dashboard") || location.pathname.startsWith("/survey"))
    ) {
      redirectPath = "/entries";
    }
  }

  useEffect(() => {
    if (redirectPath) {
      setOpen(true);
    }
  }, [redirectPath]);

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (redirectPath) {
    return (
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          navigate(redirectPath);
        }}
      >
        <DialogTitle>Access Denied</DialogTitle>
        <DialogContent>
          You do not have permission to access this page.
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
              navigate(redirectPath);
            }}
            variant="contained"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return children;
};

export default ProtectedRoute;
