import React, { useState, useEffect } from "react";
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import PieChart from "../../components/PieChart";
import BarChart from "../../components/BarChart";
import BarChartBrand from "../../components/BarChartBrand";
import StatBox2 from "../../components/StatBox2";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import { createClient } from "@supabase/supabase-js";
import PollIcon from '@mui/icons-material/Poll';

//Initialize Supabase Client
const supabase = createClient('https://hovmhwftsrfwtqkgzayn.supabase.co', 
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imhvdm1od2Z0c3Jmd3Rxa2d6YXluIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzY3NTgzOTcsImV4cCI6MjA1MjMzNDM5N30.Ocmt2Fu8E6aIzYsc9eyVSgi_eP2IWSIASMTTJNcLjfk')

const Dashboard = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [surveyCount, setSurveyCount] = useState(0);  // Create state to hold the survey entries count
    const [usedCount, setUsedCount] = useState(0);  // State for used count and percentage from tng_pin where tngUsed is true
    const [usedPercentage, setUsedPercentage] = useState(0);
    const [raceData, setRaceData] = useState([]);  // New state for race data
    const [stateData, setStateData] = useState([]);  //New state for states in Malaysia
    const [currentBrandData, setCurrentBrandData] = useState([]);  //New state for Current Brand
    const [q1TrueCount, setQ1TrueCount] = useState(0);  // New state for Q1
    const [q1FalseCount, setQ1FalseCount] = useState(0);
    const [q2TrueCount, setQ2TrueCount] = useState(0);
    const [q2FalseCount, setQ2FalseCount] = useState(0);

    useEffect(() => {
    // Function to fetch the row count from 'cust_dt'
    const fetchSurveyCount = async () => {
        const { count, error } = await supabase
        .from("cust_dt")
        .select("*", { count: "exact", head: true })
        .not("tng_code", "is", null);
      if (error) {
        console.error("Error fetching survey count:", error);
      } else {
        setSurveyCount(count);
      }
    };

    fetchSurveyCount();
    }, []);

    // Fetch count from tng_pin where tngUsed is true and calculate percentage
    useEffect(() => {
    const fetchUsedCount = async () => {
      const { count, error } = await supabase
        .from("tng_pin")
        .select("*", { count: "exact", head: true })
        .eq("tngUsed", true);
      if (error) {
        console.error("Error fetching used count:", error);
      } else {
        setUsedCount(count);
        // Calculate percentage based on 36,000 total rows
        setUsedPercentage(((count / 68000) * 100).toFixed(2));
      }
    };

    fetchUsedCount();
    }, []);

    // Fetch and process race data from cust_dt
    useEffect(() => {
    const fetchRaceData = async () => {
    const { data, error } = await supabase
      .from("cust_dt")
      .select("race")
      .not("race", "is", null);  // This filters out rows with a null tngUsed

    if (error) {
      console.error("Error fetching race data:", error);
    } else if (data) {
      // Aggregate counts for each race value
      const raceCounts = {};
      data.forEach((row) => {
        // Use "Unknown" if race is null or empty
        const raceValue = row.race || "Unknown";
        raceCounts[raceValue] = (raceCounts[raceValue] || 0) + 1;
      });
      const total = data.length;
      // Convert to an array formatted for the PieChart:
      const pieData = Object.entries(raceCounts).map(([key, count]) => ({
        id: key,
        label: key,
        value: count, // ResponsivePie uses this to size the arc
        percentage: ((count / total) * 100).toFixed(2),
      }));
      setRaceData(pieData);
    }
    };

    fetchRaceData();
    }, []);

    // Fetch and aggregate state data from cust_dt
    useEffect(() => {
    const fetchStateData = async () => {
      const { data, error } = await supabase.from("cust_dt").select("state");
      if (error) {
        console.error("Error fetching state data:", error);
      } else if (data) {
        // Aggregate counts for each state
        const stateCounts = {};
        data.forEach((row) => {
            if (row.state !== null) {
                const stateValue = row.state;
                stateCounts[stateValue] = (stateCounts[stateValue] || 0) + 1;
            }
        });
        // Convert the aggregated counts into an array formatted for the BarChart:
        const formattedData = Object.entries(stateCounts).map(
          ([state, count]) => ({
            state, // key for the state name
            count, // count value for that state
          })
        );
        setStateData(formattedData);
      }
    };

    fetchStateData();
    }, []);

    // Fetch and aggregate current_brand data from cust_dt
    useEffect(() => {
    const fetchCurrentBrandData = async () => {
    const { data, error } = await supabase.from("cust_dt").select("current_brand");
    if (error) {
      console.error("Error fetching current_brand data:", error);
    } else if (data) {
      // Aggregate counts for each current_brand, excluding null values
      const brandCounts = {};
      data.forEach((row) => {
        if (row.current_brand !== null && row.current_brand !== "") {
          const brand = row.current_brand;
          brandCounts[brand] = (brandCounts[brand] || 0) + 1;
        }
      });
      // Convert the aggregated counts into an array formatted for the BarChart:
      const formattedData = Object.entries(brandCounts).map(
        ([brand, count]) => ({
          brand, // key for the current_brand value
          count, // count for that brand
        })
      );
      setCurrentBrandData(formattedData);
    }
    };

    fetchCurrentBrandData();
    }, []);

    useEffect(() => {
    const fetchQ1Data = async () => {
    // Query for rows where q1_since1954 is true
    const { count: trueCount, error: errorTrue } = await supabase
      .from("cust_dt")
      .select("*", { count: "exact", head: true })
      .eq("q1_since1954", true);
    if (errorTrue) {
      console.error("Error fetching Q1 true count:", errorTrue);
    } else {
      setQ1TrueCount(trueCount);
    }

    // Query for rows where q1_since1954 is false
    const { count: falseCount, error: errorFalse } = await supabase
      .from("cust_dt")
      .select("*", { count: "exact", head: true })
      .eq("q1_since1954", false);
    if (errorFalse) {
      console.error("Error fetching Q1 false count:", errorFalse);
    } else {
      setQ1FalseCount(falseCount);
    }
    };

    fetchQ1Data();
    }, []);

    useEffect(() => {
    const fetchQ2Data = async () => {
    // Query for rows where q2_freshswipe is true
    const { count: trueCount, error: errorTrue } = await supabase
      .from("cust_dt")
      .select("*", { count: "exact", head: true })
      .eq("q2_freshswipe", true);
    if (errorTrue) {
      console.error("Error fetching Q2 true count:", errorTrue);
    } else {
      setQ2TrueCount(trueCount);
    }

    // Query for rows where q2_freshswipe is false
    const { count: falseCount, error: errorFalse } = await supabase
      .from("cust_dt")
      .select("*", { count: "exact", head: true })
      .eq("q2_freshswipe", false);
    if (errorFalse) {
      console.error("Error fetching Q2 false count:", errorFalse);
    } else {
      setQ2FalseCount(falseCount);
    }
    };

    fetchQ2Data();
    }, []);

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to ConnectDots | Winston Rejuvenation dashboard" />

        {/* <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box> */}
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={surveyCount}
            subtitle="Survey Entries Received"
            progress="1"
            //increase="+14%"
            icon={
              <ContactPhoneIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox2
            title={`Yes = ${q1TrueCount}`}
            title2={`No = ${q1FalseCount}`}
            subtitle="Q1 Since 1954 Answer Count"
            progress="1"
            //
            icon={
              <PollIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox2
            title={`Yes = ${q2TrueCount}`}
            title2={`No = ${q2FalseCount}`}
            subtitle="Q2 Fresh Swipe Answer Count"
            progress="1"
            //increase="+21%"
            icon={
              <PollIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
        </Box>

        {/* ROW 2 */}
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="5px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
        </Box>
        <Box
            gridColumn="span 4"
            gridRow="span 2"
            backgroundColor={colors.primary[400]}
        >
        <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
        >
          States Count
        </Typography>
        <Box height="250px" mt="-20px">
            <BarChart data={stateData} isDashboard={true} />
        </Box>
        </Box>
        </Box>
        
        {/* <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Recent Transactions
            </Typography>
          </Box>
          {mockTransactions.map((transaction, i) => (
            <Box
              key={`${transaction.txId}-${i}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box>
                <Typography
                  color={colors.greenAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  {transaction.txId}
                </Typography>
                <Typography color={colors.grey[100]}>
                  {transaction.user}
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>{transaction.date}</Box>
              <Box
                backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px"
              >
                ${transaction.cost}
              </Box>
            </Box>
          ))}
        </Box> */}
        

        {/* ROW 3 */}
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          p="30px"
        >
          <Typography variant="h5" fontWeight="600">
            TnG PIN Redemption Count
          </Typography>
          <Box 
            display="flex" 
            flexDirection="column" 
            alignItems="center" mt="25px"
            >
            <ProgressCircle size="155" progress={parseFloat(usedPercentage) / 100} />
            <Typography
              variant="h5"
              color={colors.greenAccent[500]}
              sx={{ mt: "15px" }}
            >
              {usedCount} used out of 68,000
            </Typography>
            <Typography
              variant="h5"
              color={colors.greenAccent[500]}
            >
              {usedPercentage}% Used
            </Typography>
            
          </Box>
        </Box>
        
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          p="30px"
        >
          <Typography variant="h5" fontWeight="600" sx={{ 
            mb: "15px" }}>
            Current Brand Consumed by Customer
          </Typography>
          <Box height="250px">
            <BarChartBrand data={currentBrandData} isDashboard={true}/>
          </Box>
        </Box>

        {/* NEW ROW: Race Distribution Pie Chart */}
        <Box gridColumn="span 4" gridRow="span 2" backgroundColor={colors.primary[400]} p="30px">
            <Typography variant="h5" fontWeight="600" sx={{ mb: "15px" }}>
                Race Distribution
            </Typography>
            <Box height="250px">
                <PieChart data={raceData} />
            </Box>
        </Box>

      </Box>
    </Box>
  );
};

export default Dashboard;
