import { useTheme } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { tokens } from "../theme";

const BarChartBrand = ({data, isDashboard}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <ResponsiveBar
        data={data}
        keys={["count"]}  // our aggregated data uses "count" as the value key
        indexBy="brand"   // each object should have a "state" field
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        padding={0.2}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={{ scheme: "nivo" }}
        theme={{
            tooltip: {
                container: {
                    background: "#fff",
                    color: "#000",
                    fontSize: "12px",
                    borderRadius: "4px",
                    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.25)",
                },
            },
            axis:{
                domain: {
                    line:{
                        stroke: colors.grey[100]
                    }
                },
                legend:{
                    text:{
                        fill: colors.grey[100]
                    }
                },
                ticks:{
                    line:{
                        stroke: colors.grey[100],
                        strokeWidth: 1
                    },
                    text:{
                        fill: colors.grey[100]
                    }
                }
            },
            legends:{
                text:{
                    fill: colors.grey[100]
                }
            }
        }}
        
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            //legend: 'State',
            legendPosition: 'middle',
            legendOffset: 32,
            truncateTickAt: 0
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'count',
            legendPosition: 'middle',
            legendOffset: -40,
            truncateTickAt: 0
        }}
        labelSkipWidth={7}
        labelSkipHeight={12}
        labelTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        role="application"
        ariaLabel="Nivo bar chart demo"
        barAriaLabel={e=>e.id+": "+e.formattedValue+" in country: "+e.indexValue}
    />
    )
}

export default BarChartBrand;